import { createClasses } from 'styles';
import { darken } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material//WhatsApp';
import Button from 'components/Button';
import { appData } from 'config/data.cjs';

const useClasses = createClasses((theme) => ({
	root: {
		'&&:not(.Mui-disabled)': {
			color: theme.config.darkMode ? theme.config.whatsAppColor : darken(theme.config.whatsAppColor, .25)
		}
	}
}), {
	name: 'WhatsAppButton'
});

export default function WhatsAppButton(props) {
	const {
		classes: classesProp,
		className,
		children = 'WhatsApp',
		WhatsAppIconProps,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Button
			size="small"
			href={appData.contact.whatsApp}
			{...rest}
			className={classes.root}
			color="success"
			variant="contained"
			startIcon={<WhatsAppIcon {...WhatsAppIconProps}/>}
		>
			{children}
		</Button>
	);
}
